import React from 'react';
import { Circle } from 'react-konva';
import { Coordinates } from 'src/helpers/CanvasHelpers';

interface CircleLayerProps {
	circleCoords: Coordinates[];
	radius: number;
}

export const CirclesLayer = (props: CircleLayerProps) => {
	return (
		<>
			{props.circleCoords.map((coord, i) => {
				return (
					<Circle
						x={coord.x}
						y={coord.y}
						radius={props.radius}
						key={i}
						stroke={'#FF0000'}
						strokeWidth={5}
					/>
				);
			})}
		</>
	);
};
