import React from 'react';

function ExpandSVG() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
			<g fill="#54CADC" stroke="none">
				<path
					d="M9.031 0H3.69a1.031 1.031 0 000 2.062h1.822L.3 7.274a1.033 1.033 0 00.73 1.757c.272.001.534-.106.728-.298L6.97 3.52v1.824a1.03 1.03 0 102.061 0V0z"
					transform="translate(12.867)"
				></path>
				<path
					d="M8.733.302a1.031 1.031 0 00-1.459 0L2.062 5.517V3.693a1.028 1.028 0 00-1.03-1.032A1.031 1.031 0 000 3.693v5.344h5.341a1.031 1.031 0 000-2.062H3.52L8.733 1.76a1.031 1.031 0 000-1.458z"
					transform="translate(0 12.864)"
				></path>
				<path
					d="M2.658 8.003a1.033 1.033 0 001.03 1.03H9.03V3.69a1.03 1.03 0 10-2.061 0v1.823L1.757.3A1.033 1.033 0 000 1.029c0 .272.107.534.299.728l5.213 5.215H3.69a1.031 1.031 0 00-1.031 1.03z"
					transform="translate(12.868 12.868)"
				></path>
				<path d="M8.733 7.274L3.52 2.062h1.821a1.028 1.028 0 001.032-1.03A1.028 1.028 0 005.34 0H0v5.344a1.03 1.03 0 102.062 0V3.52l5.212 5.213a1.029 1.029 0 001.455-.004 1.029 1.029 0 00.004-1.455z"></path>
			</g>
		</svg>
	);
}

export default ExpandSVG;
