import React from 'react';

export function BlueClose() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
			<title>Close</title>
			<g fill="#54CADC" stroke="none">
				<path
					d="M10.908.414a1.403 1.403 0 00-1.98 0L5.699 3.642 2.484.414a1.407 1.407 0 00-1.993 0 1.407 1.407 0 000 1.992l3.228 3.216L.491 8.838A1.4 1.4 0 000 9.85a1.4 1.4 0 001.457 1.457c.391-.016.757-.194 1.012-.49L5.7 7.602l3.215 3.215a1.404 1.404 0 001.98-1.98L7.678 5.623l3.215-3.216a1.405 1.405 0 00.014-1.992z"
					transform="translate(9.744 9.821)"
				></path>
				<path
					fillRule="evenodd"
					d="M4.523 4.523a15.447 15.447 0 0121.841 0 15.448 15.448 0 010 21.841 15.448 15.448 0 01-21.841 0 15.448 15.448 0 010-21.841zm1.986 19.855a12.632 12.632 0 0017.87 0 12.631 12.631 0 000-17.87 12.632 12.632 0 00-17.87 0 12.632 12.632 0 000 17.87z"
				></path>
			</g>
		</svg>
	);
}
