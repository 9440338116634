import React from 'react';
import { Image } from 'react-konva';
import useImage from 'use-image';
import Konva from 'konva';

interface Props {
	sheet1Url: string;
	width?: number;
	height?: number;
}

export const Sheet1Image = (props: Props) => {
	const [image] = useImage(props.sheet1Url);
	const imageRef = React.useRef() as any; // Using "as any" because the default type may be undefined.

	// when image is loaded we need to cache the shape
	React.useEffect(() => {
		if (image) {
			// you many need to reapply cache on some props changes like shadow, stroke, etc.
			imageRef.current.cache();
			// since this update is not handled by "react-konva" and we are using Konva methods directly
			// we have to redraw layer manually
			imageRef.current.getLayer().batchDraw();
		}
	}, [image]); // the ref type here as any because it also doesn't match the type above
	return (
		<Image
			ref={imageRef as any}
			width={props.width}
			height={props.height}
			image={image}
			filters={[Konva.Filters.Grayscale]}
		/>
	);
};
