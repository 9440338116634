import React from 'react';
import styles from './styles.module.css';
import ReactModal from 'react-modal';

import { Stage, Layer, Rect } from 'react-konva';

import { ArrowButton } from 'src/components/ArrowButton';
import { isOverlapping, Coordinates } from 'src/helpers/CanvasHelpers';

import { ModalView } from './components/ModalView';
import { BlueClose } from './components/ModalView/components/BlueClose';

import { DiscrepanciesQuestionSpec } from '../../index';
import ExpandSVG from './SVGs/ExpandSVG';
import { Sheet1Image } from './components/Sheet1Image/component';
import { Sheet2Image } from './components/Sheet2Image';
import { CirclesLayer } from './components/CirclesLayer';
import { KonvaEventObject } from 'konva/types/Node';
import { LayerClick } from 'src/model/LayerClick';
import { SettingsManager } from 'src/helpers/SettingsManager';
import { Tooltip } from 'src/components/Tooltip';
import { UserManager } from 'src/helpers/UserManager';

interface Props {
	currQuestionNum: number;
	questionTotal: number;
	nextQuestion: (answer?: string | undefined) => void;
	currQuestionSpec: DiscrepanciesQuestionSpec;
}

interface State {
	currCoordinates: Coordinates[];
	showModal: boolean;
	maxClicks?: number;
}

ReactModal.setAppElement('#___gatsby');

export class Questions extends React.Component<Props, State> {
	private radius: number;
	state: State = {
		currCoordinates: [],
		showModal: false,
	};
	constructor(props: Props) {
		super(props);
		this.radius = 20;
	}

	async componentDidMount() {
		this.setState({
			maxClicks: Number(
				await SettingsManager.getSetting(
					'MaxClicksPerPicture',
					await UserManager.getAuthenticatedUserId()
				)
			),
		});
	}

	handleOpenModal = () => {
		this.setState({ showModal: true });
	};

	handleCloseModal = () => {
		this.setState({ showModal: false });
	};

	handleCanvasClick = (coord: Coordinates) => {
		const { currCoordinates, maxClicks } = this.state;
		let canDraw = true;
		for (const stateCoordinate of currCoordinates) {
			const isTooClose = isOverlapping(coord, stateCoordinate, this.radius);
			// if it is then remove the current one from state, and draw all coordinates remaining in state
			if (isTooClose) {
				// dont draw
				canDraw = false;
				this.setState((prevState) => ({
					currCoordinates: prevState.currCoordinates.filter((el) => el != stateCoordinate),
				}));
				break;
			}
		}
		// if you got through all the state's coordinates and didnt set the flag to false, add the new circle to state
		// ... but only if there are still more answers that can be provided for this question
		if (canDraw && (maxClicks ? currCoordinates.length < maxClicks : true)) {
			this.setState({ currCoordinates: this.state.currCoordinates.concat(coord) });
		}
	};

	render() {
		const sheet1Url = require(`./tableImages/${this.props.currQuestionSpec.sheet1Url}`);
		const sheet2Url = require(`./tableImages/${this.props.currQuestionSpec.sheet2Url}`);
		const skipTooltip = `Submits this question with no answer. The question cannot be attempted later.`;

		return (
			<div className={styles.questionsContainer}>
				<div className={styles.questionText}>
					<p>Spot the discrepancies between the two tables below.</p>
					<p>Click each of the incorrect values on the second sheet.</p>
				</div>
				<div className={styles.canvasContainer}>
					<div className={styles.sheet1Container}>
						<h2 className={styles.sheet1Heading}>Sheet One</h2>
						<Stage className={styles.sheet1Canvas} width={607} height={530}>
							<Layer>
								<Sheet1Image sheet1Url={sheet1Url} />
							</Layer>
						</Stage>
					</div>
					<div className={styles.sheet2Container}>
						<h2 className={styles.sheet2Heading}>Sheet Two</h2>
						<div className={styles.sheet2CanvasContainer}>
							<Stage className={styles.answerCanvases} width={607} height={530}>
								<Layer>
									<Sheet2Image sheet2Url={sheet2Url} />
								</Layer>
								<Layer
									onClick={(e: KonvaEventObject<LayerClick>) => {
										const coord = { x: e.evt.layerX, y: e.evt.layerY };
										this.handleCanvasClick(coord);
									}}
								>
									{/* "Dummy" rectangle to make the Layer fill the canvas so it can be clickable */}
									<Rect width={607} height={530} />
									{this.state.currCoordinates && (
										<CirclesLayer circleCoords={this.state.currCoordinates} radius={this.radius} />
									)}
								</Layer>
							</Stage>
						</div>
					</div>
				</div>
				<div className={styles.submitContainer}>
					<button
						className={styles.fullScreenBtn}
						onClick={() => {
							this.setState({ showModal: true });
						}}
					>
						Full Screen
						<ExpandSVG />
					</button>

					<ReactModal
						isOpen={this.state.showModal}
						contentLabel="Modal Zoomed View"
						className={styles.modal}
						overlayClassName={styles.overlay}
					>
						<button className={styles.closeModalButton} onClick={this.handleCloseModal}>
							<BlueClose />
						</button>
						<ModalView
							currCoordinates={this.state.currCoordinates}
							sheet1Url={sheet1Url}
							sheet2Url={sheet2Url}
							radius={this.radius}
							handleCanvasClick={this.handleCanvasClick}
						/>
					</ReactModal>

					<ArrowButton
						caption="Submit Answer"
						onClick={() => {
							this.state.currCoordinates &&
								this.props.nextQuestion(JSON.stringify(this.state.currCoordinates));
							this.setState({ currCoordinates: [] });
						}}
						isDisabled={this.state.currCoordinates === null}
					/>

					<button
						className={styles.skipButton}
						onClick={() => {
							this.setState({ currCoordinates: [] });
						}}
					>
						Reset
					</button>
					<Tooltip caption={skipTooltip}>
						<button
							className={styles.skipButton}
							onClick={() => {
								this.setState({ currCoordinates: [] });
								this.props.nextQuestion();
							}}
						>
							Skip &gt;
						</button>
					</Tooltip>
				</div>
			</div>
		);
	}
}
