import React from 'react';
import styles from './styles.module.css';
import { Stage, Layer, Rect } from 'react-konva';
import { Sheet1Image } from '../Sheet1Image/component';
import { Sheet2Image } from '../Sheet2Image';
import { CirclesLayer } from '../CirclesLayer';
import { Coordinates, convertCoordinatesToOriginal } from 'src/helpers/CanvasHelpers';
import { KonvaEventObject } from 'konva/types/Node';
import { LayerClick } from 'src/model/LayerClick';

interface State {}
interface Props {
	currCoordinates: Coordinates[];
	sheet1Url: string;
	sheet2Url: string;
	radius: number;
	handleCanvasClick: (e: any) => void;
}

export class ModalView extends React.Component<Props, State> {
	private zoomFactor: number;

	constructor(props: Props) {
		super(props);
		this.zoomFactor = 1.5;
	}

	render() {
		return (
			<div className={styles.questionsContainer}>
				<div className={styles.canvasContainer}>
					<div className={styles.sheet1Container}>
						<h2 className={styles.sheet1Heading}>Sheet 1</h2>
						<Stage
							className={styles.sheet1Canvas}
							width={607 * this.zoomFactor}
							height={530 * this.zoomFactor}
						>
							<Layer>
								<Sheet1Image
									sheet1Url={this.props.sheet1Url}
									width={607 * this.zoomFactor}
									height={530 * this.zoomFactor}
								/>
							</Layer>
						</Stage>
					</div>
					<div className={styles.sheet2Container}>
						<h2 className={styles.sheet2Heading}>Sheet 2</h2>
						<div className={styles.sheet2CanvasContainer}>
							<Stage
								className={styles.answerCanvases}
								width={607 * this.zoomFactor}
								height={530 * this.zoomFactor}
							>
								<Layer>
									<Sheet2Image
										sheet2Url={this.props.sheet2Url}
										width={607 * this.zoomFactor}
										height={530 * this.zoomFactor}
									/>
								</Layer>
								<Layer
									onClick={(e: KonvaEventObject<LayerClick>) => {
										const coord = { x: e.evt.layerX, y: e.evt.layerY };
										this.props.handleCanvasClick(
											convertCoordinatesToOriginal(coord, this.zoomFactor)
										);
									}}
								>
									{/* "Dummy" rectangle to make the Layer fill the canvas so it can be clickable */}
									<Rect width={607 * this.zoomFactor} height={530 * this.zoomFactor} />
									{this.props.currCoordinates && (
										<CirclesLayer
											circleCoords={this.props.currCoordinates.map((coord: Coordinates) => {
												return { x: coord.x * this.zoomFactor, y: coord.y * this.zoomFactor };
											})}
											radius={this.props.radius}
										/>
									)}
								</Layer>
							</Stage>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
